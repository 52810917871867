<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="defaultHref"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <span *ngIf="cigar?.Name">{{ cigar?.Name }}</span>
      <ion-skeleton-text
        animated
        style="width: 60%"
        *ngIf="!cigar?.Name"
      ></ion-skeleton-text>
    </ion-title>
  </ion-toolbar>

  <ion-toolbar color="primary" class="action-buttons">
    <ion-buttons>
      <ion-button (click)="addTo()">
        <ion-icon slot="start" name="add-outline"></ion-icon>
        Add
      </ion-button>
      <ion-button *ngIf="cigarLog?.List" (click)="remove()">
        <ion-icon slot="start" name="trash-outline"></ion-icon>
        Delete
      </ion-button>
      <ion-button
        (click)="shareOptions()"
        [ngClass]="{ 'no-delete': !cigarLog }"
        *ngIf="!cigar?.IsCustom"
      >
        <ion-icon slot="start" name="share-social-outline"></ion-icon>
        Share
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="page-container cigar-details-container">
    <div class="cigar-preview-wrapper">
      <div class="info-wrapper" *ngIf="cigarLog">
        <div class="location-details">
          <div class="place">{{ cigarLog?.Location }}</div>
          <div class="details">
            <span>{{ cigarLog?.Date | date }}</span>
          </div>
        </div>
        <ion-button
          size="large"
          fill="clear"
          (click)="openCigarLogInfoModal()"
          class="edit-location"
          *ngIf="cigarLog"
        >
          <ion-icon slot="icon-only" name="create"></ion-icon>
        </ion-button>
      </div>
      <div
        *ngIf="cigar"
        class="cigar-preview"
        imgModal
        [imgModalSrc]="backgroundImageUrl | createSource"
        [style.background-image]="setBackgroundImageUrl()"
        [style.background-color]="backgroundImageUrl ? '#000' : ''"
        [style.background-size]="backgroundImageUrl ? '150%' : ''"
      ></div>
    </div>
    <div class="ion-text-end">
      <ion-button
        color="primary"
        size="small"
        *ngIf="recordId"
        (click)="reportBadResult()"
        [disabled]="reportSent"
      >
        Report wrong result
      </ion-button>
    </div>

    <div class="inner-cigar-wrapper">
      <ion-list lines="full" mode="ios" *ngIf="cigar">
        <ion-item class="brand">
          <cigar-brand-logo slot="start" [cigar]="cigar"></cigar-brand-logo>
          <ion-label class="ion-text-wrap">
            <h1 class="ion-text-uppercase ion-text-bold">
              {{ cigar.Name }}
              {{
                cigar?.Attributes?.Length || cigar?.Length
                  | cigarSize : cigar?.Attributes?.RingGauge || cigar?.RingGauge
              }}
            </h1>
            <div class="rating" *ngIf="!cigar.IsCustom">
              <cs-rating-stars-readonly
                [rating]="cigar?.RatingSummary?.AverageRating"
                [count]="cigar?.RatingSummary?.RatingCount"
                [size]="16"
                [showNumbers]="true"
              />
            </div>
          </ion-label>
          <ion-button
            slot="end"
            *ngIf="cigar.IsCustom"
            size="large"
            fill="clear"
            color="primary"
            (click)="editCustomCigar()"
          >
            <ion-icon slot="icon-only" name="create"></ion-icon>
          </ion-button>
        </ion-item>

        <ng-container *ngIf="cigar && !cigar?.IsCustom">
          <ion-item>
            <cigar-price
              [cigarPrices]="cigar.Prices"
              [boxQuantityMin]="cigar?.MinBoxQty"
              [boxQuantityMax]="cigar?.MaxBoxQty"
            >
            </cigar-price>
          </ion-item>
          <ion-item *ngIf="cigar.Prices?.DisplayPartnerPrices">
            <cigar-price
              [type]="'Partner price'"
              [cigarPrices]="cigar.PartnerPrices"
              [boxQuantityMin]="cigar?.MinBoxQty"
              [boxQuantityMax]="cigar?.MaxBoxQty"
            >
            </cigar-price>
          </ion-item>
          <ion-item *ngIf="cigar.Prices?.DisplayPartnerPrices">
            <cigar-price
              [type]="'Best price'"
              [cigarPrices]="cigar.bestPrices()"
              [boxQuantityMin]="cigar?.MinBoxQty"
              [boxQuantityMax]="cigar?.MaxBoxQty"
            >
            </cigar-price>
          </ion-item>
        </ng-container>
      </ion-list>

      <cigar-neptune-price
        *ngIf="cigar && !cigar?.IsCustom"
        [price]="cigar.NeptunePrices"
        [aux]="cigar.Aux"
        [link]="cigar.AdUrl"
        [id]="cigar.LineId"
      />

      <ion-list lines="full" mode="ios" *ngIf="cigar">
        <ion-list-header *ngIf="cigar.MyRating?.Rating">
          <ion-label>My Rating</ion-label>
          <ion-button
            fill="clear"
            color="primary"
            (click)="openCigarReviewModal()"
          >
            <ion-icon slot="icon-only" name="create"></ion-icon>
          </ion-button>
        </ion-list-header>
        <ion-list-header *ngIf="!cigar.MyRating?.Rating">
          <ion-label>How would you rate it?</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label class="ion-text-wrap">
            <div
              style="display: flex"
              class="ion-justify-content-between ion-margin-bottom"
            >
              <cs-rating-stars
                [rating]="cigar.MyRating?.Rating || 0"
                fillColor="#FDC70C"
                borderColor="#FDC70C"
                [rateable]="!userRatedProduct"
                [setAll]="true"
                (setAllStars)="openCigarReviewModal($event)"
                [size]="25"
              />
              <span style="flex: 1"></span>
              <span class="ion-text-end">{{
                cigar.MyRating?.CreatedOn | date
              }}</span>
            </div>
            <cs-text-expand
              [text]="cigar.MyRating?.Comment"
              [maxLength]="400"
            ></cs-text-expand>
          </ion-label>
        </ion-item>
        <ion-list-header *ngIf="cigarLog?.List">
          <ion-label>Price</ion-label>
          <ion-button (click)="openCigarPriceAlert()">
            <ion-icon
              slot="icon-only"
              [name]="cigarLog?.Price ? 'create' : 'add'"
            ></ion-icon>
          </ion-button>
        </ion-list-header>
        <ion-item *ngIf="cigarLog?.Price">
          <ion-label class="ion-text-wrap">
            {{ cigarLog.Price | currency }}
          </ion-label>
        </ion-item>
        <ion-list-header>
          <ion-label>Personal Note </ion-label>
          <ion-button (click)="openNoteModal()">
            <ion-icon
              slot="icon-only"
              [name]="cigar.MyNote ? 'create' : 'add'"
            ></ion-icon>
          </ion-button>
        </ion-list-header>
        <ion-item *ngIf="cigar.MyNote">
          <ion-label class="ion-text-wrap" *ngIf="cigar.MyNote">
            <cs-text-expand
              [text]="cigar.MyNote.Note"
              [maxLength]="400"
            ></cs-text-expand>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list lines="full" mode="ios" *ngIf="cigar?.ProductId">
        <ion-list-header>
          <ion-label>Smoking Time</ion-label>
          <ion-button (click)="openSmokingTimeModal()">
            <ion-icon
              slot="icon-only"
              [name]="cigar.MyCigarFeatures?.SmokingTime ? 'create' : 'add'"
            ></ion-icon>
          </ion-button>
        </ion-list-header>
        <ion-item>
          <ion-label
            *ngIf="cigar.MyCigarFeatures?.SmokingTime || cigar.SmokingTime"
            >{{
              cigar.MyCigarFeatures?.SmokingTime || cigar.SmokingTime
            }}min</ion-label
          >
        </ion-item>
      </ion-list>

      <ion-list lines="full" mode="ios" *ngIf="cigar?.Description">
        <ion-list-header>
          <ion-label>Description</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label class="ion-text-wrap">
            <cs-text-expand
              [text]="cigar?.Description"
              [maxLength]="400"
            ></cs-text-expand>
          </ion-label>
        </ion-item>
      </ion-list>

      <!-- <div class="description-wrapper">
        <div class="inner-wrapper">
          <p>Description</p>
        </div>
        <div class="inner-wrapper">
          <div
            class="comment"
            *ngIf="cigar?.Description; else descriptionSkeleton"
          >
            {{ cigar?.Description }}
          </div>
          <ng-template #descriptionSkeleton>
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </ng-template>
        </div>
      </div> -->

      <ion-list
        lines="full"
        mode="ios"
        class="product-photos-wrapper"
        *ngIf="cigar?.Images?.length"
      >
        <ion-list-header>
          <ion-label>Product Photos ({{ cigar?.Images?.length }})</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>
            <swiper-container
              pagination="true"
              pagination-clickable="true"
              class="pb-5"
            >
              <swiper-slide
                *ngFor="let image of cigar.Images"
                class="w-full text-center"
              >
                <img
                  [src]="
                    image.ImageUrl
                      | createSource : 'assets/images/image_placeholder.svg'
                  "
                  src-fallback="assets/images/image_placeholder.svg"
                  imgModal
                  loading="lazy"
                />
              </swiper-slide>
            </swiper-container>
          </ion-label>
        </ion-item>
      </ion-list>

      <ng-container *ngIf="cigar">
        <ion-list lines="full" mode="ios" *ngIf="cigar?.Attributes">
          <ion-list-header>
            <ion-label>Specifications</ion-label>
          </ion-list-header>
          <ng-container *ngFor="let attr of ProductAttributes">
            <ion-item
              button
              detail
              *ngIf="
                attr.key !== 'Strength' &&
                cigar.Attributes[attr.key] &&
                cigar.Attributes[attr.key + 'Description']
              "
              (click)="openAttributeModal(attr.key)"
            >
              <ion-label>
                <h2>{{ attr.value }}</h2>
                <h3>{{ cigar.Attributes[attr.key] }}</h3>
              </ion-label>
            </ion-item>
            <!-- without link -->
            <ion-item
              *ngIf="
                attr.key !== 'Strength' &&
                cigar.Attributes[attr.key] &&
                !cigar.Attributes[attr.key + 'Description']
              "
            >
              <ion-label>
                <h2>{{ attr.value }}</h2>
                <h3>{{ cigar.Attributes[attr.key] }}</h3>
              </ion-label>
            </ion-item>
            <!-- Strength (special case )-->
            <ion-item
              *ngIf="attr.key === 'Strength' && cigar.Attributes[attr.key]"
            >
              <ion-label>
                <h2>{{ attr.value }}</h2>
                <h3>{{ cigar.Attributes[attr.key] }}</h3>
                <ion-range
                  class="strength"
                  ticks="true"
                  snaps="true"
                  [step]="1"
                  [max]="2"
                  [value]="
                    cigar.Attributes[attr.key] === 'Mild'
                      ? 0
                      : cigar.Attributes[attr.key] === 'Medium'
                      ? 1
                      : 2
                  "
                  [disabled]="true"
                >
                </ion-range>
              </ion-label>
            </ion-item>
          </ng-container>
        </ion-list>
      </ng-container>

      <div class="shapes" #cigarShapeWrapper>
        <ion-list lines="full" mode="ios" *ngIf="cigar?.Shapes?.length">
          <ion-list-header>
            <ion-label>Shapes</ion-label>
          </ion-list-header>
          <ion-item
            *ngFor="let shape of cigar.Shapes"
            button
            details
            [routerLink]="['./shape', 'P-' + shape.Id]"
          >
            <ion-label class="ion-text-wrap">
              <img
                *ngIf="shape.ImageOfSingleUrl"
                class="object-cover shape-image"
                [src]="
                  shape.ImageOfSingleUrl
                    | createSource : 'assets/images/cigar_placeholder.svg'
                "
                src-fallback="assets/images/cigar_placeholder.svg"
                [style.width.px]="getCigarImageWidth(shape)"
              />
              <h2>{{ shape.Name }}</h2>
              <h3>
                {{ shape.Attributes?.Shape }} {{ shape.Attributes?.Length }} x
                {{ shape.Attributes?.RingGauge }}
              </h3>
              <div class="rating">
                <cs-rating-stars-readonly
                  [rating]="shape.RatingSummary?.AverageRating"
                  [count]="shape.RatingSummary?.RatingCount"
                  [size]="16"
                  [showNumbers]="true"
                />
              </div>

              <div class="cigar-price-wrapper">
                <cigar-price
                  [type]="'MSRP'"
                  [cigarPrices]="shape.Prices"
                  [boxQuantityMin]="shape?.MinBoxQty"
                  [boxQuantityMax]="shape?.MaxBoxQty"
                >
                </cigar-price>

                <cigar-price
                  *ngIf="shape.Prices?.DisplayPartnerPrices"
                  [type]="'Partner price'"
                  [cigarPrices]="shape.PartnerPrices"
                  [boxQuantityMin]="shape?.MinBoxQty"
                  [boxQuantityMax]="shape?.MaxBoxQty"
                >
                </cigar-price>
              </div>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
      <ion-list
        lines="full"
        mode="ios"
        *ngIf="
          cigar?.BandHistory && !cigar.IsCustom && cigar.BandHistory?.length > 0
        "
        class="brand-history"
      >
        <ion-list-header>
          <ion-label>Brand History</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label class="ion-text-wrap">
            <ng-container *ngFor="let image of cigar.BandHistory">
              <img
                imgModal
                [src]="
                  image.ImageUrl
                    | createSource : 'assets/images/image_placeholder.svg'
                "
                src-fallback="assets/images/image_placeholder.svg"
              />
            </ng-container> </ion-label></ion-item
      ></ion-list>

      <ion-list lines="full" mode="ios" *ngIf="cigar?.SocialPosts">
        <ion-list-header>
          <ion-label>Social Posts</ion-label>
        </ion-list-header>
        <ion-item (click)="showProductPosts()" button detail>
          <ion-label class="ion-text-wrap">
            View What People are Posting ({{ cigar.SocialPosts }} post<span
              *ngIf="cigar.SocialPosts > 1"
              >s</span
            >)
          </ion-label></ion-item
        ></ion-list
      >

      <ion-list
        lines="full"
        mode="ios"
        *ngIf="cigar?.AdditionalAttributes?.length"
      >
        <ion-list-header>
          <ion-label>Magazine Ratings</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label class="ion-text-wrap magazine-ratings">
            <div *ngFor="let attribute of cigar.AdditionalAttributes">
              <div
                [class]="
                  attribute.AttributeId == ProductAttribute.CARatings
                    ? 'aficionado'
                    : 'insider'
                "
              >
                {{ attribute.Value }}
              </div>
              <div *ngIf="attribute.Info">{{ attribute.Info }}</div>
            </div>
          </ion-label>
        </ion-item></ion-list
      >

      <ion-list
        lines="full"
        mode="ios"
        class="user-ratings"
        *ngIf="!cigar?.IsCustom"
      >
        <ion-list-header>
          <ion-label>User Ratings</ion-label>
        </ion-list-header>
        <ion-item
          (click)="openReviewsModal(5)"
          button
          detail
          [disabled]="cigar?.RatingSummary?.Rated5 === 0"
        >
          <cs-rating-stars-readonly
            slot="start"
            [rating]="5"
            [count]="5"
            [size]="14"
            fillColor="#FDC70C"
            borderColor="#FDC70C"
          />
          <ion-label>
            <div class="scale">
              <div
                class="fill"
                [style.width.%]="
                  cigar?.RatingSummary | ratingSummaryRatio : 'Rated5'
                "
              ></div>
            </div>
          </ion-label>
          <p slost="end">
            {{ cigar?.RatingSummary?.Rated5 }}
          </p></ion-item
        >
        <ion-item
          (click)="openReviewsModal(4)"
          button
          detail
          [disabled]="cigar?.RatingSummary?.Rated4 === 0"
        >
          <cs-rating-stars-readonly
            slot="start"
            [rating]="4"
            [count]="4"
            [size]="14"
            fillColor="#FDC70C"
            borderColor="#FDC70C"
          />
          <ion-label>
            <div class="scale">
              <div
                class="fill"
                [style.width.%]="
                  cigar?.RatingSummary | ratingSummaryRatio : 'Rated4'
                "
              ></div>
            </div>
          </ion-label>
          <p slost="end">
            {{ cigar?.RatingSummary?.Rated4 }}
          </p></ion-item
        >
        <ion-item
          (click)="openReviewsModal(3)"
          button
          detail
          [disabled]="cigar?.RatingSummary?.Rated3 === 0"
        >
          <cs-rating-stars-readonly
            slot="start"
            [rating]="3"
            [count]="3"
            [size]="14"
            fillColor="#FDC70C"
            borderColor="#FDC70C"
          />
          <ion-label>
            <div class="scale">
              <div
                class="fill"
                [style.width.%]="
                  cigar?.RatingSummary | ratingSummaryRatio : 'Rated3'
                "
              ></div>
            </div>
          </ion-label>
          <p slost="end">
            {{ cigar?.RatingSummary?.Rated3 }}
          </p></ion-item
        >
        <ion-item
          (click)="openReviewsModal(2)"
          button
          detail
          [disabled]="cigar?.RatingSummary?.Rated2 === 0"
        >
          <cs-rating-stars-readonly
            slot="start"
            [rating]="2"
            [count]="2"
            [size]="14"
            fillColor="#FDC70C"
            borderColor="#FDC70C"
          />
          <ion-label>
            <div class="scale">
              <div
                class="fill"
                [style.width.%]="
                  cigar?.RatingSummary | ratingSummaryRatio : 'Rated2'
                "
              ></div>
            </div>
          </ion-label>
          <p slost="end">
            {{ cigar?.RatingSummary?.Rated2 }}
          </p></ion-item
        >
        <ion-item
          (click)="openReviewsModal(1)"
          button
          detail
          [disabled]="cigar?.RatingSummary?.Rated1 === 0"
        >
          <cs-rating-stars-readonly
            slot="start"
            [rating]="1"
            [count]="1"
            [size]="14"
            fillColor="#FDC70C"
            borderColor="#FDC70C"
          />
          <ion-label>
            <div class="scale">
              <div
                class="fill"
                [style.width.%]="
                  cigar?.RatingSummary | ratingSummaryRatio : 'Rated1'
                "
              ></div>
            </div>
          </ion-label>
          <p slost="end">
            {{ cigar?.RatingSummary?.Rated1 }}
          </p></ion-item
        >
      </ion-list>
      <tag-list [tags]="cigar?.Tags" *ngIf="!cigar?.IsCustom"></tag-list>
    </div>
  </div>
</ion-content>

<ion-header>
  <ion-toolbar>
    <ion-title>Reviews</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()"
        ><ion-icon slot="icon-only" name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-segment
      value="with-comment"
      (ionChange)="
        toggleReviewsToDisplay($event.detail.value === 'with-comment')
      "
    >
      <ion-segment-button value="with-comment">
        With Comment
      </ion-segment-button>
      <ion-segment-button value="all"> All Reviews </ion-segment-button>
    </ion-segment>
    <ion-progress-bar type="indeterminate" *ngIf="isLoading"></ion-progress-bar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <ion-list lines="full">
    <ion-item color="light" *ngIf="!isLoading && !reviews?.length">
      <ion-label>No review</ion-label>
    </ion-item>
    <ion-item *ngFor="let review of reviews">
      <ion-label class="ion-text-wrap">
        <h3>Reviewed on {{ review.CreatedOn | date }}</h3>
        <p *ngIf="review.Comment?.length" [innerHTML]="review.Comment | nl2br">
          You will remove these restraints and leave...
        </p>
      </ion-label>
      <cs-rating-stars-readonly
        slot="end"
        [rating]="review.Rating"
        [size]="16"
      />
    </ion-item>
    <ion-infinite-scroll
      *ngIf="total === reviews.length"
      threshold="100px"
      (ionInfinite)="nextPage($event)"
    >
      <ion-infinite-scroll-content> </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-list>
</ion-content>
